<template>
  <div class="container">
    <h1>Login</h1>

    <input v-model="username" type="text" placeholder="Benutzername" /><br />
    <input v-model="password" type="password" placeholder="Passwort" /><br />
    <button @click="onLogin">Einloggen</button>
  </div>
</template>

<script>
import { ref } from 'vue'
import { login } from '@/utils/login'

export default {
  name: 'Login',
  setup () {
    const username = ref('')
    const password = ref('')

    async function onLogin () {
      if (username.value && password.value) {
        const response = await login(username.value, password.value)

        if (!response) {
          alert('Benutzername oder Passwort falsch')
        }
      }
    }

    return {
      username,
      password,
      onLogin
    }
  }
}
</script>

<style scoped>

</style>


import { logout } from '@/utils/login'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {},
  setup () {
    function onLogout () {
      logout()
    }

    return {
      onLogout
    }
  }
})

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import AutoComplete from 'primevue/autocomplete'
import InputNumber from 'primevue/inputnumber'
import axios from 'axios'
import './utils/axios'

import './assets/primeicons.css'

axios.defaults.baseURL = process.env.VUE_APP_HOST + '/api/'

const app = createApp(App)

app.use(router)
app.use(PrimeVue)

app.component('AutoComplete', AutoComplete)
app.component('InputNumber', InputNumber)

app.mount('#app')

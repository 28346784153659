

import { defineComponent } from 'vue'
import { logout, useToken } from '@/utils/login'
import Login from '@/components/Login.vue'

export default defineComponent({
  name: 'App',
  components: { Login },
  setup () {
    const token = useToken()

    function onLogout () {
      logout()
    }

    return {
      token,
      logout
    }
  }
})

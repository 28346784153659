import axios from 'axios'
import { ref } from 'vue'

const tokenKey = 'carab-pos-token'

const token = ref('')

const savedToken = localStorage.getItem(tokenKey)

if (savedToken) {
  token.value = savedToken
}

export async function login (username: string, password: string) {
  const response = await axios.post('/login', {
    username: username,
    password: password
  })

  if (response.data && response.data.jwt) {
    token.value = response.data.jwt
    localStorage.setItem(tokenKey, token.value)

    return true
  } else {
    return false
  }
}

export function useToken () {
  return token
}

export function logout () {
  token.value = ''
  localStorage.removeItem(tokenKey)
}
